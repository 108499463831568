import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import navbarstyle from "../../style/navbar.module.less";
import { WhitepaperButton } from "../buttons/Whitepaper";
import { HyperpadButton } from "../buttons/Hyperpad";
import { ConnectButton } from "../buttons/Connect";
import useDevice from "../../hooks/useDevice";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import "../../style/wallet.less";

function truncateAddress(address, prefixLength = 4, suffixLength = 4) {
  if (address.length <= prefixLength + suffixLength) {
    return address;
  }

  const prefix = address.substring(0, prefixLength);
  const suffix = address.substring(address.length - suffixLength);

  return `${prefix}...${suffix}`;
}

export const NavBar = () => {
  const { isMobile, isDesktop } = useDevice();
  const btnSize = isMobile ? "small-btn" : "large-btn";
  const [currentPubKey, setCurrentPubKey] = useState(null);
  const navigate = useNavigate();

  const wallet = useWallet();
  const connection = useConnection();

  useEffect(() => {
    if (isDesktop && wallet?.wallet?.adapter) {
      wallet.connect();
    }
  }, [wallet.wallet]);

  useEffect(() => {
    if (wallet.publicKey && !currentPubKey) {
      setCurrentPubKey(wallet.publicKey);
    } else if (!wallet.publicKey && currentPubKey) {
      navigate(0);
    }
  }, [wallet.publicKey]);

  return (
    <div className={navbarstyle.navConatiner}>
      <div className={navbarstyle.logo}>
        <a href="/" style={{ display: "flex", flexDirection: "column" }}>
          <img src="/images/logo.png" height={isMobile ? 50 : 90} />
        </a>
      </div>

      <div
        className={`${navbarstyle.navButton} ${
          isMobile ? "customWallet smallWallet" : "customWallet largeWallet"
        }`}
      >
        <HyperpadButton btnSize={btnSize} />
        <WalletMultiButton>
          {wallet.connected
            ? truncateAddress(wallet?.publicKey?.toBase58())
            : "CONNECT"}
        </WalletMultiButton>
      </div>
    </div>
  );
};
