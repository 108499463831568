import React from "react";
import Button from "antd/es/button";

export const HyperButton = ({
  text,
  onClick,
  btnSize = "large-btn",
  ...props
}) => {
  return (
    <Button
      className={`primary-button ${btnSize}`}
      onClick={onClick}
      {...props}
    >
      {text}
    </Button>
  );
};
