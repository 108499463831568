import React from "react";
import { HyperButton } from "../common/HyperButton";

export const ChartButton = ({ btnSize }) => {
  return (
    <HyperButton
      btnSize={btnSize}
      text="Chart"
      onClick={() => {
        const chartUrl =
          "https://dexscreener.com/solana/ASFCZADStvfR4ef6MBiXisZSZDxMmqA72PgLwFKqgWMM";
        window.open(chartUrl, "_blank");
      }}
    />
  );
};
