import React from "react";
import { HyperButton } from "../common/HyperButton";

export const ContractButton = ({ btnSize }) => {
  return (
    <HyperButton
      btnSize={btnSize}
      text="contract"
      onClick={() => {
        const contractUrl =
          "https://solscan.io/token/3WJusXBAretidMWr327VKt9CiejX7PoPX4PLjLxCkoWi";
        window.open(contractUrl, "_blank");
      }}
    />
  );
};
