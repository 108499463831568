import React from "react";
import { HyperButton } from "../common/HyperButton";

export const TelegramButton = ({ btnSize }) => {
  return (
    <HyperButton
      btnSize={btnSize}
      text="telegram"
      onClick={() => {
        const twitterUrl = "https://t.me/hyperSOL";
        window.open(twitterUrl, "_blank");
      }}
    />
  );
};
