import React from "react";
import { HyperLayout } from "./components/layout/layout";
import { BrowserRouter as Router } from "react-router-dom";
import { Wallet } from "./components/wallet/wallet";
import "./style/styles.css";
console.log("process env", process.env);
const App = () => {
  return (
    <Router>
      <Wallet>
        <HyperLayout />
      </Wallet>
    </Router>
  );
};
export default App;
