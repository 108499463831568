import React, { useState, useEffect } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { TwitterButton } from "../buttons/Twitter";
import { TelegramButton } from "../buttons/Telegram";
import useDevice from "../../hooks/useDevice";
import presaleStyles from "../../style/presale.module.less";
import { HyperCard } from "../common/HyperCard";
import { HyperInfo } from "../common/HyperInfo";
import { HyperInput } from "../common/HyperInput";
import { PresaleApeButton } from "../buttons/PresaleApe";
import { WhitepaperButton } from "../buttons/Whitepaper";
import {
  MAX_PRESALE_SOL,
  MIN_PRESALE_SOL,
  PRESALE_KEY,
  PRESALE_TARGET_KEY,
} from "../../constants/values";
import message from "antd/es/message";
import {
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
  sendAndConfirmTransaction,
} from "@solana/web3.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import axios from "axios";
import {
  PRESALE_INVEST,
  PRESALE_STATUS,
  PRESALE_USER_STATUS,
} from "../../constants/urls";
import { ProgressBar } from "../common/ProgressBar";

const presaleColor = "#52e1ff";

const convertLamportToSOL = (solAmount) => {
  return parseInt(solAmount) / LAMPORTS_PER_SOL;
};
function formatNumber(num) {
  if (Math.abs(num) < 1000)
    return (parseInt(num * 100) / 100).toFixed(2).toString();

  const symbols = ["K", "M", "B", "T", "Q", "P", "E"];
  const tier = (Math.log10(Math.abs(num)) / 3) | 0;
  if (tier == 0) return num.toString();
  const suffix = symbols[tier - 1];
  const scale = Math.pow(10, tier * 3);
  const scaled = num / scale;
  const formatted = Math.floor(scaled * 100) / 100; // Keep up to 2 decimal places
  return formatted.toFixed(2) + suffix; // Append suffix after converting to string
}
const calculatePercent = (a, b) => {
  if (b != 0) {
    return Math.min(parseInt((parseFloat(a) * 100) / parseFloat(b)), 100);
  } else {
    return 0;
  }
};

const PresaleFooter = () => {
  const { isMobile } = useDevice();
  const btnSize = isMobile ? "small-btn" : "large-btn";
  return (
    <div className={presaleStyles.footer}>
      <TwitterButton btnSize={btnSize} />
      {isMobile && <WhitepaperButton btnSize={btnSize} />}
      <TelegramButton btnSize={btnSize} />
    </div>
  );
};

const formatTime = (time) => {
  const hours = Math.floor(time / 60);
  const minutes = Math.floor(time % 60);
  return `${hours.toString().padStart(2, "0")}h - ${minutes
    .toString()
    .padStart(2, "0")}m`;
};

const PresaleCard = ({
  presaleStatus,
  userPresaleStatus,
  setUserPresaleStatus,
  setPresaleStatus,
  timer,
  isTimerRunning,
  ...props
}) => {
  const [inputSol, setInputSol] = useState(null);
  const [isFormInSubmission, setIsFormInSubmission] = useState(false);
  const wallet = useWallet();
  const connection = useConnection();
  const [messageApi, contextHolder] = message.useMessage();

  const handleApeIn = async () => {
    // check if wallet is connected or not

    if (!wallet.connected) {
      message.error("please connect to wallet to proceed");
      return null;
    }

    // validate input
    if (
      parseFloat(inputSol) < parseFloat(presaleStatus.min_sol) ||
      parseFloat(inputSol) + parseFloat(userPresaleStatus?.solInvested) >
        parseFloat(presaleStatus.max_sol)
    ) {
      message.error(
        `Sol Invested must be between ${formatNumber(
          parseFloat(presaleStatus.min_sol)
        )} to ${formatNumber(parseFloat(presaleStatus.max_sol))}`,
        1
      );
      return null;
    }
    try {
      setIsFormInSubmission(true);

      // build transaction and send it to backend for processing
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: wallet.publicKey,
          toPubkey: new PublicKey(PRESALE_TARGET_KEY),
          lamports: parseFloat(inputSol) * LAMPORTS_PER_SOL,
        })
      );
      // transaction build, now sign it
      let { blockhash, lastValidBlockHeight } =
        await connection.connection.getLatestBlockhash("finalized");
      transaction.recentBlockhash = blockhash;
      transaction.lastValidBlockHeight = lastValidBlockHeight;
      transaction.feePayer = wallet.publicKey;

      let signedTransaction = await wallet.signTransaction(transaction);

      let key = "updatable";

      messageApi.open({
        key,
        type: "loading",
        content: "Transaction in process, please wait...",
      });

      // send the signed transaction to backend for processing
      axios({
        method: "POST",
        url: PRESALE_INVEST,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: {
          signedTransaction: signedTransaction.serialize(),
          public_key: wallet.publicKey.toBase58(),
          presale_key: PRESALE_KEY,
        },
      })
        .then((result) => {
          const solInvested = convertLamportToSOL(result?.data?.lamports) || 0;
          setUserPresaleStatus((prev) => ({
            ...prev,
            solInvested: solInvested,
          }));
          setPresaleStatus((prev) => ({
            ...prev,
            solRaised: result?.data?.sol_raised || "0",
          }));
          messageApi.open({
            key,
            type: "success",
            content: "Transaction Successfull!",
            duration: 10,
          });
        })
        .catch((err) => {
          console.log("error occured while submission", err);
          messageApi.open({
            key,
            type: "error",
            content: err?.response?.data?.error || "Transaction failed!",
            duration: 2,
          });
        })
        .finally(() => {
          setInputSol(null);
          setIsFormInSubmission(false);
        });
    } catch (cstmError) {
      setIsFormInSubmission(false);
    }
  };
  const handleWalletConnect = () => {
    if (!wallet.connected) {
      wallet.connect();
    }
  };

  const isPresaleEnded = !presaleStatus?.end_time
    ? false
    : !!(parseInt(presaleStatus?.end_time || 0) < new Date().getTime());

  return (
    <div {...props}>
      {contextHolder}
      <HyperCard bodyStyle={{ backgroundColor: "yellow" }}>
        <div className={presaleStyles.rowsWrap}>
          <div className={presaleStyles.oneColumnInfo}>
            <ProgressBar
              percent={`${
                calculatePercent(
                  convertLamportToSOL(presaleStatus?.solRaised),
                  presaleStatus?.solTarget
                ) || 0
              }%`}
              text={`Raised = ${formatNumber(
                convertLamportToSOL(presaleStatus?.solRaised)
              )} / ${presaleStatus?.solTarget} SOL`}
            />
          </div>
          <div className={presaleStyles.twoColumnInfo}>
            <HyperInfo
              text={`1 SOL = ${presaleStatus?.tokensPerSol || "-"} HYPERSOL`}
              color={"#000"}
              backgroundColor={presaleColor}
            />
            <HyperInfo
              text={`Min / Max = ${presaleStatus?.min_sol || "-"} / ${
                presaleStatus?.max_sol || "-"
              } SOL`}
              color={"#000"}
              backgroundColor={presaleColor}
            />
          </div>
          <div className={presaleStyles.oneColumnInfo}>
            <HyperInfo
              text={`Your Stake = ${formatNumber(
                userPresaleStatus.solInvested || "0"
              )} SOL / ${formatNumber(
                userPresaleStatus.solInvested *
                  parseInt(presaleStatus.tokensPerSol) || "0"
              )} HYPERSOL`}
              color={"#000"}
              backgroundColor={presaleColor}
            />
          </div>
          <div className={presaleStyles.twoColumnInfo}>
            <HyperInput
              placeholder="Enter amount"
              disabled={isFormInSubmission}
              color={"#000"}
              backgroundColor={presaleColor}
              value={inputSol}
              onChange={(event) => setInputSol(event.target.value)}
            />
            {!wallet.connected ? (
              <WalletMultiButton>CONNECT</WalletMultiButton>
            ) : (
              <PresaleApeButton
                disabled={isFormInSubmission}
                onClick={
                  isTimerRunning
                    ? () => {}
                    : isPresaleEnded
                    ? () => {}
                    : handleApeIn
                }
                text={
                  isTimerRunning
                    ? `Starts In :: ${formatTime(timer)}`
                    : isPresaleEnded
                    ? "Presale Ended"
                    : "Ape In"
                }
              />
            )}
          </div>
        </div>
      </HyperCard>
    </div>
  );
};

export const Presale = React.memo(() => {
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [timer, setTimer] = useState(0);

  const wallet = useWallet();
  const connection = useConnection();
  const [presaleStatus, setPresaleStatus] = useState({
    solRaised: 0,
    solTarget: 0,
    presale_status: null,
  });
  const [userPresaleStatus, setUserPresaleStatus] = useState({
    solInvested: 0,
    tokenRecieved: 0,
  });

  useEffect(() => {
    if (wallet.connected) {
      // since wallet is connected, call for user presale metrics
      axios({
        method: "GET",
        url: PRESALE_USER_STATUS,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        params: {
          public_key: wallet.publicKey.toBase58(),
          presale_key: PRESALE_KEY,
        },
      })
        .then((res) => {
          const solInvested = convertLamportToSOL(res?.data?.lamports);
          setUserPresaleStatus({
            solInvested: solInvested,
          });
        })
        .catch((err) => {
          console.log("error is", err);
        });
    }
    return () => {};
  }, [wallet.connected]);

  useEffect(() => {
    let intervalId = null;
    // call for presale metrics - presale status, total Raised, total Raising
    axios({
      method: "GET",
      url: PRESALE_STATUS,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      params: {
        presale_key: PRESALE_KEY,
      },
    })
      .then((res) => {
        const presale_start_time = res?.data?.start_time;
        const current_time = new Date().getTime();

        if (parseInt(current_time) < parseInt(presale_start_time)) {
          const timeLeft = parseInt(
            (parseInt(presale_start_time) - parseInt(current_time)) /
              (60 * 1000)
          );
          setTimer(timeLeft);
          timeLeft ? setIsTimerRunning(true) : setIsTimerRunning(false);
          intervalId = setInterval(() => {
            setTimer((prevTimer) => {
              const newTimeRemaining = prevTimer - 1;
              if (newTimeRemaining <= 0) {
                clearInterval(intervalId);
                setIsTimerRunning(false);
                return 0;
              }
              return newTimeRemaining;
            });
          }, 60 * 1000);
        }

        setPresaleStatus({
          solRaised: res?.data?.sol_raised,
          solTarget: res?.data?.hard_cap,
          tokensPerSol: res?.data?.tokens_per_sol,
          min_sol: res?.data?.min_sol,
          max_sol: res?.data?.max_sol,
          start_time: res?.data?.start_time,
          end_time: res?.data?.end_time,
          presale_status: "",
        });
      })
      .catch((err) => {
        console.log("somthing went wrong while fetching presale status");
      });
    return () => {
      clearInterval(intervalId);
      setIsTimerRunning(false);
    };
  }, []);

  return (
    <div className={presaleStyles.presaleContainer}>
      <div className={presaleStyles.presaleContent}>
        <div className={presaleStyles.presaleTagLine}>
          <p>Join HyperSOL Presale </p>
        </div>
        <div className={presaleStyles.cardContainer}>
          {/* <div className={presaleStyles.leftConatiner} /> */}
          <PresaleCard
            className={presaleStyles.rightConatiner}
            presaleStatus={presaleStatus}
            userPresaleStatus={userPresaleStatus}
            setUserPresaleStatus={setUserPresaleStatus}
            setPresaleStatus={setPresaleStatus}
            timer={timer}
            isTimerRunning={isTimerRunning}
          />
        </div>
      </div>
      <div>
        <PresaleFooter />
      </div>
    </div>
  );
});
