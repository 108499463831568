export const SOLANA_MAINNET = process.env.SOLANA_MAINNET;
export const SOLANA_DEVNET = process.env.SOLANA_DEVNET;
export const SOLANA_TESTNET = process.env.SOLANA_TESTNET;

export const PRESALE_INVEST = `${process.env.BACKEND_URL}/join_presale`;
export const PRESALE_STATUS = `${process.env.BACKEND_URL}/presale_status`;
export const PRESALE_USER_STATUS = `${process.env.BACKEND_URL}/presale_user_status`;
export const TOKEN_METRICS = `${process.env.BACKEND_URL}/token_metrics`;
export const USER_TOKEN_METRICS = `${process.env.BACKEND_URL}/user_token_metrics`;
export const CLAIM_USER_REWARDS = `${process.env.BACKEND_URL}/claim_user_rewards`;
