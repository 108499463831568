import React, { useReducer, createContext } from "react";
import storage from "../utils/storage";
import {
  SOLANA_DEVNET,
  SOLANA_MAINNET,
  SOLANA_TESTNET,
} from "../constants/urls";

export const AppContext = createContext();

export const AVAILABLE_NETWORKS = ["MAINNET", "TESTNET", "DEVNET"];
export const NETWORKS = {
  MAINNET: "MAINNET",
  TESTNET: "TESTNET",
  DEVNET: "DEVNET",
};

const INITIAL_STATE = {
  currentNetworkURL: SOLANA_MAINNET,
};

// It converts actions to the states.
const appReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_NETWORK":
      storage.set("NETWORK_TYPE", payload);
      return {
        ...state,
        currentNetworkURL: SOLANA_MAINNET,
      };
    default:
      return state;
  }
};

export const AppContextProvider = ({ children }) => {
  const store = useReducer(appReducer, {
    ...INITIAL_STATE,
  });
  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};
