import React from "react";
import { HyperButton } from "../common/HyperButton";

export const WhitepaperButton = ({ btnSize, ...props }) => {
  return (
    <HyperButton
      btnSize={btnSize}
      text="WHITEPAPER"
      onClick={() => {
        const twitterUrl = "https://whitepaper.hypersol.xyz/v1";
        window.open(twitterUrl, "_blank");
      }}
      {...props}
    />
  );
};
