import React, { useEffect, useState } from "react";
import { HyperCard } from "../common/HyperCard";
import homeStyle from "../../style/home.module.less";
import { HyperInfo } from "../common/HyperInfo";
import { ClaimRewards } from "../buttons/ClaimRewards";
import { TwitterButton } from "../buttons/Twitter";
import { TelegramButton } from "../buttons/Telegram";
import { ChartButton } from "../buttons/Chart";
import { ContractButton } from "../buttons/Contract";
import useDevice from "../../hooks/useDevice";
import { WhitepaperButton } from "../buttons/Whitepaper";
import axios from "axios";
import message from "antd/es/message";
import {
  CLAIM_USER_REWARDS,
  TOKEN_METRICS,
  USER_TOKEN_METRICS,
} from "../../constants/urls";
import { convertLamportToSOL, formatNumber } from "../../utils/solUtils";
import {
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
  sendAndConfirmTransaction,
  Token,
} from "@solana/web3.js";
import {
  FEE_COLLECTOR_ACCOUNT,
  TOKEN_MINT_ADDRESS,
} from "../../constants/values";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_2022_PROGRAM_ID,
  getAssociatedTokenAddressSync,
} from "@solana/spl-token";

const colorButton = "#282729";

const RewardCard = ({
  children,
  tokenMetrics,
  userTokenMetrics,
  claimMyRewards,
  tokenBalance,
  isFormInSubmission,
  ...props
}) => {
  return (
    <div {...props} style={{ width: "100%" }}>
      <HyperCard children={children} bodyStyle={{ backgroundColor: "yellow" }}>
        <div className={homeStyle.rowsWrap}>
          <div className={homeStyle.twoColumnInfo}>
            <HyperInfo
              text={`${tokenMetrics.token_burn_change} $HYPERSOL Burned`}
              color={colorButton}
              backgroundColor={"#fff"}
            />
            <HyperInfo
              text={`${tokenMetrics.sol_distribute_change} $SOL distributed`}
              color={colorButton}
              backgroundColor={"#fff"}
            />
          </div>
          <div className={homeStyle.twoColumnInfo}>
            <HyperInfo
              text={`Balance: ${tokenBalance} $HYPERSOL `}
              color={colorButton}
              backgroundColor={"#86f8ff"}
            />
            <HyperInfo
              text={`Total Earnings: ${
                Math.floor(
                  convertLamportToSOL(userTokenMetrics.sol_deposited) * 1000
                ) / (1000).toString()
              } SOL`}
              color={colorButton}
              backgroundColor={"#86f8ff"}
            />
          </div>
          <div className={homeStyle.twoColumnInfo}>
            <HyperInfo
              text={`SOL Unclaimed:  ${
                Math.floor(
                  convertLamportToSOL(
                    userTokenMetrics.sol_deposited -
                      userTokenMetrics.sol_withdrawn
                  ) * 1000
                ) / (1000).toString()
              }`}
              color={colorButton}
              backgroundColor={"#86f8ff"}
            />
            <ClaimRewards
              disabled={isFormInSubmission}
              claimMyRewards={isFormInSubmission ? () => {} : claimMyRewards}
            />
          </div>
        </div>
      </HyperCard>
    </div>
  );
};

const HomeFooter = () => {
  const { isMobile, isDesktop } = useDevice();
  const btnSize = isMobile ? "small-btn" : "large-btn";
  return (
    <>
      <div className={homeStyle.footer}>
        <WhitepaperButton btnSize={btnSize} />
        <TwitterButton btnSize={btnSize} />
        <TelegramButton btnSize={btnSize} />
        <ChartButton btnSize={btnSize} />
        <ContractButton btnSize={btnSize} />
      </div>
    </>
  );
};

export const Home = () => {
  const { isDesktop } = useDevice();
  const wallet = useWallet();
  const connection = useConnection();
  const [messageApi, contextHolder] = message.useMessage();
  const [isFormInSubmission, setIsFormInSubmission] = useState(false);
  const [walletATA, setWalletATA] = useState(null);

  const [tokenMetrics, setTokenMetrics] = useState({
    token_burn_change: 0,
    sol_distribute_change: 0,
  });
  const [userTokenMetrics, setUserTokenMetrics] = useState({
    sol_deposited: "0",
    sol_withdrawn: "0",
  });
  const [tokenBalance, setTokenBalance] = useState(0);

  const claimMyRewards = async () => {
    // claim my rewards
    const availableLamports =
      BigInt(userTokenMetrics.sol_deposited) -
      BigInt(userTokenMetrics.sol_withdrawn);

    // add this check after testing
    if (availableLamports < BigInt(0.0001 * LAMPORTS_PER_SOL)) {
      message.error("Minimum withdrawal amount is 0.001 SOL");
      return;
    }

    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey: new PublicKey(FEE_COLLECTOR_ACCOUNT),
        toPubkey: wallet.publicKey,
        lamports: availableLamports,
      })
    );
    try {
      setIsFormInSubmission(true);
      // transaction build, now sign it
      let { blockhash, lastValidBlockHeight } =
        await connection.connection.getLatestBlockhash("finalized");
      transaction.recentBlockhash = blockhash;
      transaction.lastValidBlockHeight = lastValidBlockHeight;
      transaction.feePayer = wallet.publicKey;

      const signedTransction = await wallet.signTransaction(transaction);

      let key = "processing_transaction";

      messageApi.open({
        key,
        type: "loading",
        content: "Transaction in process, please wait...",
      });
      // send the signed transaction to backend for processing
      axios({
        method: "POST",
        url: CLAIM_USER_REWARDS,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        data: {
          signedTransaction: signedTransction.serialize({
            requireAllSignatures: false,
          }),
          public_key: wallet.publicKey.toBase58(),
          wallet_ata: walletATA,
        },
      })
        .then((result) => {
          // on successful sol claimed, update the UI
          setUserTokenMetrics({
            sol_deposited: result?.data?.sol_deposited || "0",
            sol_withdrawn: result?.data?.sol_withdrawn || "0",
          });
          messageApi.open({
            key,
            type: "success",
            content: "Transaction Successfull!",
            duration: 10,
          });
        })
        .catch((err) => {
          console.log("error occured while processing", err);
          messageApi.open({
            key,
            type: "error",
            content:
              err?.response?.data?.error ||
              "Transaction failed!, please contact team",
            duration: 2,
          });
        })
        .finally(() => {
          setIsFormInSubmission(false);
        });
    } catch (err) {
      console.log("something went wrong on processing the transaction");
      setIsFormInSubmission(false);
    }
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: TOKEN_METRICS,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        setTokenMetrics({
          token_burn_change: result?.data?.token_burn_change || "0",
          sol_distribute_change: result?.data?.sol_distribute_change || "0",
        });
      })
      .catch((err) => {
        console.log("error in fetching token metrics");
      });
  }, []);

  useEffect(() => {
    if (wallet.connected) {
      const walletATA_local = getAssociatedTokenAddressSync(
        new PublicKey(TOKEN_MINT_ADDRESS),
        wallet.publicKey,
        false,
        TOKEN_2022_PROGRAM_ID,
        ASSOCIATED_TOKEN_PROGRAM_ID
      );
      setWalletATA(walletATA_local.toBase58());

      connection.connection
        .getParsedTokenAccountsByOwner(wallet.publicKey, {
          mint: new PublicKey(TOKEN_MINT_ADDRESS),
        })
        .then((res) => {
          const useTokenAmount =
            res?.value?.[0]?.account?.data?.parsed?.info?.tokenAmount?.uiAmount;
          const formattedAmount = formatNumber(parseInt(useTokenAmount) || 0);
          setTokenBalance(formattedAmount);
        })
        .catch((err) => {
          console.log("failed while fetching user balance");
        });

      // Load the token instance associated with the token mint

      axios({
        method: "GET",
        url: USER_TOKEN_METRICS,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        params: {
          public_key: wallet.publicKey.toBase58(),
          wallet_ata: walletATA_local.toBase58(),
        },
      })
        .then((result) => {
          setUserTokenMetrics({
            sol_deposited: result?.data?.sol_deposited || "0",
            sol_withdrawn: result?.data?.sol_withdrawn || "0",
          });
        })
        .catch((err) => {
          console.log("error while getting user metrics", err);
        });
    }
  }, [wallet.connected]);

  return (
    <div className={homeStyle.homeContainer}>
      {contextHolder}
      <div className={homeStyle.homeContent}>
        <div className={homeStyle.homeTagline}>
          {/* <p className={homeStyle.mainTitle}>HYPERSOL</p> */}
          <p className={homeStyle.aboutText}>
            Hold <span className={homeStyle.highlight}> $HYPERSOL </span> to
            earn passive rewards in
            <span className={homeStyle.highlight}> $SOL </span>
          </p>
        </div>
        <div className={homeStyle.homecardContainer}>
          <p className={homeStyle.maintagline}>Earn In Burn Out</p>
          <RewardCard
            tokenMetrics={tokenMetrics}
            userTokenMetrics={userTokenMetrics}
            tokenBalance={tokenBalance}
            claimMyRewards={claimMyRewards}
            isFormInSubmission={isFormInSubmission}
            className={homeStyle.homeContentLeft}
          />
        </div>
      </div>
      <div>
        <HomeFooter />
      </div>
    </div>
  );
};
