import { getPlatformInfo } from "../utils/getPlatformInfo";
import { useMedia } from "./useMedia";

const MEDIA_TYPES = Object.freeze({
  MOBILE: "mobile",
  TABLET: "TABLET",
  FABLET: "fablet",
  DESKTOP: "desktop",
});

export const sizes = {
  [MEDIA_TYPES.MOBILE]: "480px",
  [MEDIA_TYPES.TABLET]: "768px",
  [MEDIA_TYPES.FABLET]: "1000px",
  [MEDIA_TYPES.DESKTOP]: "1200px",
};

const media = {
  [MEDIA_TYPES.MOBILE]: `(max-width: ${sizes[MEDIA_TYPES.MOBILE]})`,
  [MEDIA_TYPES.TABLET]: `(max-width: ${sizes[MEDIA_TYPES.TABLET]})`,
  [MEDIA_TYPES.FABLET]: `(max-width: ${sizes[MEDIA_TYPES.FABLET]})`,
  [MEDIA_TYPES.DESKTOP]: `(max-width: ${sizes[MEDIA_TYPES.DESKTOP]})`,
};

export default function useDevice() {
  const currentBreakPoint = useMedia(
    Object.values(media),
    Object.keys(media),
    getPlatformInfo()?.isDesktop ? MEDIA_TYPES.DESKTOP : MEDIA_TYPES.MOBILE
  );
  return {
    isMobile: currentBreakPoint === MEDIA_TYPES.MOBILE,
    isTablet: currentBreakPoint === MEDIA_TYPES.TABLET,
    isFablet: currentBreakPoint === MEDIA_TYPES.FABLET,
    isDesktop: currentBreakPoint === MEDIA_TYPES.DESKTOP,
  };
}
