import React from "react";

export const ProgressBar = ({ percent, text }) => {
  return (
    <div className="progress-container">
      <div
        className="progress"
        style={{ height: "100%", width: percent, backgroundColor: "#57d6f8" }}
      ></div>
      <div className="progress-content">
        <p className="progress-content-text">{text}</p>
      </div>
    </div>
  );
};
