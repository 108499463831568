import React from "react";
import { HyperButton } from "../common/HyperButton";

export const TwitterButton = ({ btnSize }) => {
  return (
    <HyperButton
      text="X"
      onClick={() => {
        const twitterUrl = "https://twitter.com/hypersolx";
        window.open(twitterUrl, "_blank");
      }}
      btnSize={btnSize}
    />
  );
};
