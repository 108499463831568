import { LAMPORTS_PER_SOL } from "@solana/web3.js";

export const convertLamportToSOL = (solAmount) => {
  console.log(parseInt(solAmount) / LAMPORTS_PER_SOL);
  return parseInt(solAmount) / LAMPORTS_PER_SOL;
};
export const formatNumber = (num) => {
  if (Math.abs(num) < 1000)
    return (parseInt(num * 100) / 100).toFixed(2).toString();

  const symbols = ["K", "M", "B", "T", "Q", "P", "E"];
  const tier = (Math.log10(Math.abs(num)) / 3) | 0;
  if (tier == 0) return num.toString();
  const suffix = symbols[tier - 1];
  const scale = Math.pow(10, tier * 3);
  const scaled = num / scale;
  const formatted = Math.floor(scaled * 100) / 100; // Keep up to 2 decimal places
  return formatted.toFixed(2) + suffix; // Append suffix after converting to string
};
