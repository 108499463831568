import React from "react";
import { HyperButton } from "../common/HyperButton";
import { useWallet } from "@solana/wallet-adapter-react";

export const PresaleApeButton = ({ btnSize, disabled, onClick, text }) => {
  return (
    <HyperButton
      disabled={disabled}
      btnSize={btnSize}
      text={text}
      onClick={onClick}
    />
  );
};
