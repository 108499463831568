import React from "react";
import Card from "antd/es/card/Card";

export const HyperCard = ({ children }) => {
  return (
    <Card
      hoverable
      className="card-base"
      style={{
        width: "100%",
      }}
    >
      {children}
    </Card>
  );
};
