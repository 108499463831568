import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import Layout from "antd/es/layout";
import { Header } from "antd/es/layout/layout";
import { Content } from "antd/es/layout/layout";
import layoutStyle from "../../style/layout.module.less";
import { Route, Routes } from "react-router-dom";
import { NavBar } from "./Navbar";
import { Presale } from "../presale/presale";
import { Home } from "../home/home";
import useDevice from "../../hooks/useDevice";

export const HyperLayout = () => {
  const location = useLocation();
  const { isMobile } = useDevice();

  const bgImage = useMemo(() => {
    switch (location.pathname) {
      case "/presale":
        return "../images/presalebg1.jpg";
      case "/":
        return "../images/homebg.jpg";
      default:
        return "../images/bg1.jpeg";
    }
  }, [location.pathname, isMobile]);

  function handleScroll() {
    if (window.scrollY > 50) {
      document
        .querySelector(`.${layoutStyle.layoutHeader}`)
        .classList.add(layoutStyle.scrolled);
    } else {
      document
        .querySelector(`.${layoutStyle.layoutHeader}`)
        .classList.remove(layoutStyle.scrolled);
    }
  }

  // Listen for scroll events
  window.addEventListener("scroll", handleScroll);

  return (
    <Layout
      style={{ backgroundImage: `url(${bgImage})` }}
      className={layoutStyle.layoutContainer}
    >
      <Header className={layoutStyle.layoutHeader}>
        <NavBar />
      </Header>
      <Layout className={layoutStyle.layoutWrap}>
        <Layout className={layoutStyle.layoutBox}>
          <Content className={layoutStyle.layoutContent}>
            <Routes>
              <Route path="/presale" element={<Presale />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
