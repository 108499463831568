import React from "react";
import { HyperButton } from "../common/HyperButton";

export const HyperpadButton = ({ btnSize }) => {
  return (
    <HyperButton
      btnSize={btnSize}
      text="HyperPad"
      onClick={() => {
        const appUrl = "https://app.hypersol.xyz";
        window.open(appUrl, "_blank");
      }}
    />
  );
};
