import Input from "antd/es/input/Input";
import React from "react";

export const HyperInput = ({
  inputSize,
  disabled,
  value,
  onChange,
  ...props
}) => {
  return (
    <Input
      onChange={onChange}
      disabled={disabled}
      value={value}
      className={`text-input ${inputSize}`}
      placeholder={props?.placeholder}
    />
  );
};
