import React from "react";
import { HyperButton } from "../common/HyperButton";

export const ClaimRewards = ({ disabled, claimMyRewards }) => {
  const buttonStyle = {
    backgroundColor: "#9800ed",
    color: "white",

    // Add any other styles you want to customize
  };

  return (
    <HyperButton
      text={"CLAIM REWARDS"}
      disabled={disabled}
      onClick={claimMyRewards}
      style={buttonStyle}
    />
  );
};
