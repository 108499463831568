import React from "react";

export const HyperInfo = ({ text, color, backgroundColor,  }) => {
  return (
    <div
      className="hyper-info-content"
      style={{ backgroundColor: backgroundColor }}
    >
      <p className="hyper-info-text" style={{ color: color }}>
        {text}
      </p>
    </div>
  );
};
